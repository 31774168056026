<template>
  <div class="st-pusher">
    <div class="st-content" ref="main">
      <div class="st-content-inner" :class="isRTLPage? 'rtl' : 'ltr'">
        <div class="main clearfix">
          <TopHeader :kindTheme="kindTheme"/>
          <TopNav :kindTheme="kindTheme"/>
          <NavMenu />
          <Banner :imageList="bannerImages" type="home" />
          <search-tab0 v-if="kindTheme === 0"/>
          <Banner2 v-if="kindTheme === 2"/>
          <LandingContent/>
          <Footer page="home" />
          <bottom-left-call />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'LandingPage',
  mixins: [getBannerImage],
  components: {
    TopHeader: () => import('@/components/content/TopHeader/TopHeader'),
    TopNav: () => import('@/components/content/TopNav/TopNav'),
    LandingContent: () => import('@/components/landing/LandingContent'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
    Banner: () => import('@/components/content/Banner'),
    Banner2: () => import('@/components/content/Banner/BannerTheme2'),
    SearchTab0: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    NavMenu: () => import('@/components/menu/NavMenu'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
    kindTheme() {
      let kind = 0;
      if (this.device === 'mobile') {
        kind = 2;
      }
      return kind;
    },
  },
  async mounted() {
    await this.$store.dispatch('FETCH_DEAL_TYPE_LIST');

    window.localStorage.setItem('landing-url', window.location.search);
    this.$i18n.locale = 'he';
    this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
    window.localStorage.setItem('language', this.$i18n.locale);
    this.addGoogleAnalytice();
    this.setCookie();
  },
  methods: {
    addGoogleAnalytice() {
      // const head = document.getElementsByTagName('head')[0];
      const body = document.getElementsByTagName('body')[0];
      body.insertAdjacentHTML('afterbegin', '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TP7KKB4" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>');
    },
    setCookie() {
      const d = new Date();
      d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `utm_source = ${this.landingInfo.utm_source}; ${expires} ;path=/`;
    },
  },
};
</script>

<style scoped>

</style>
